import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ModalEnd({
  onClickBrowse,
  onClickRestart,
  score,
  foundStations,
  totalStations,
  visible,
}) {
  return (
    <Modal backdrop="static" keyboard={false} size="lg" show={visible}>
      <Modal.Header>
        <Modal.Title>Time&apos;s up!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <p>Your score</p>
              <h2>{score}</h2>
            </Col>
            <Col>
              <p>Stations found</p>
              <h2>{foundStations}/{totalStations}</h2>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={onClickBrowse}>Browse stations</Button>
        <Button variant="success" onClick={onClickRestart}>Play again</Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalEnd.propTypes = {
  onClickBrowse: PropTypes.func.isRequired,
  onClickRestart: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  foundStations: PropTypes.number.isRequired,
  totalStations: PropTypes.number.isRequired,
  visible: PropTypes.bool,
};

ModalEnd.defaultProps = {
  visible: true,
};

export default ModalEnd;
